import React from "react";
import PropTypes from "prop-types";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useDataQualityOverviewTable from "./useDataQualityOverviewTable";
import CircularProgress from '@mui/material/CircularProgress';

function DataQualityTable({}) {
  const { tableData, loading, error } = useDataQualityOverviewTable();

  if (loading) {
    return (
      <MDBox p={3}>
        <MDTypography variant="h6" fontWeight="medium" gutterBottom>
          <CircularProgress />
        </MDTypography>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox p={3}>
        <MDTypography variant="h6" color="error" gutterBottom>
          Error: {error}
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <MDBox p={3}>
      <MDTypography variant="h6" fontWeight="medium" gutterBottom>
        Detectors List
      </MDTypography>
      <DataTable
        table={tableData}
        canSearch
      />
    </MDBox>
  );
}


export default DataQualityTable;
