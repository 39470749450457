//import { MultiCascader } from 'rsuite';
//import { TreePicker, MultiCascader, Modal, Button } from 'rsuite';
// import 'rsuite/MultiCascader/styles/index.css';
// import 'rsuite/TreePicker/styles/index.css';
//import 'rsuite/SelectPicker/styles/index.css';
// import 'rsuite/dist/rsuite.min.css';
import MDBox from "components/MDBox";
import "../../../assets/Css/QDfilters.css";
import "../../../assets/Css/general.css";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import TextField from '@mui/material/TextField';
import MDButton from "components/MDButton";
import { formatDateDQ } from 'modules/formatDates';
import Overview from "./diagrams/Overview";


const dqCorrelations = [
    {
        label: 'Detectors Data',
        value: 'detectors',
        subs: [
            { label: 'All', value: 'all', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'YUNEX', value: 'yunex', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'SWARCO', value: 'swarco', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'SCAE', value: 'scae', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] }
        ]
    },
    {
        label: 'Speed  - Travel times data',
        value: 'speedTravel',
        subs: [
            { label: 'TomTom Data', value: 'tomtomfcd', dqualities: ["Overview", "Completeness", "Coverage"] },
            { label: 'Taxi Data', value: 'taxifcd', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
            { label: 'Blootooth Data', value: 'bluetooth', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
        ]
    },
    {
        label: 'Public transport data',
        value: 'publicTransport',
        subs: [
            { label: 'OASTH Data', value: 'oasth', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
        ]
    },
    {
        label: 'Events',
        value: 'events',
        subs: [
            { label: 'TomTom Traffic', value: 'tomtomtfc', dqualities: ["Overview", "Accuracy"] },
            { label: 'Police Traffic', value: 'policetfc', dqualities: ["Overview", "Accuracy"] }
        ]
    }
]
function Filters(data) {
    const [dataSource, setDataSource] = useState("detectors");
    const [dataSubSource, setDataSubSource] = useState("all");
    const [dataQuality, setDataQuality] = useState("Overview");

    const [location, setlocation] = useState("Networks");

    const [timeType, setTimeType] = useState("24h");
    const now = new Date();
    const [customDates, setCustomDates] = useState({
        from: formatDateDQ(new Date(now.getTime() - 24 * 60 * 60 * 1000)),
        to: formatDateDQ(now)
    });
    const [customHours, setCustomHours] = useState(2);
    // useEffect(() => { 

    // }, []); 


    function getSubSourceValues(targetValue) {
        const target = dqCorrelations.find(item => item.value === targetValue);
        return target.subs; // Return an empty array if the target value is not found
    }
    function getDataQualities(targetValue) {
        // Find the parent node
        const parent = dqCorrelations.find(item => item.value === dataSource);
        if (!parent) return null;
        // Find the child node within the parent's subs
        const child = parent.subs.find(sub => sub.value === targetValue);
        return child ? child.dqualities : null;
    }
    const handleChangeSource = (event, newValue) => {
        const newDef = getSubSourceValues(newValue)[0].value;
        setDataQuality("Overview");
        setDataSubSource(newDef);
        setDataSource(newValue);
    }
    const handleChangeSubSource = (event, newValue) => {
        const newDef = getDataQualities(newValue)[0];
        setDataQuality(newDef);
        setDataSubSource(newValue);
    }
    const handleChangeDataQuality = (event, newValue) => {
        setDataQuality(newValue);
    }
    const handleCustomDates = (event, newValue) => {
        console.log(event.target.name, event.target.value);
        setCustomDates({ ...customDates, [event.target.name]: event.target.value });
    }
    const apply = () => {
        console.log(customDates);
    }
    function applyEnabled() {
        if (timeType === "customH") {
            if (customHours > 0) {
                return true;
            } else {
                return false;
            }
        }
        if (timeType === "customD") {
            const fromDate = new Date(customDates.from);
            const toDate = new Date(customDates.to);
            if (customDates.from !== "" && customDates.to !== "" && (fromDate < toDate)) {
                return true;
            } else {
                return false;
            }
        }
        return true;

    }

    return (
        <>
            <Card style={{ marginTop: '3px' }}>
                <div className='rawContainer' >
                    <MDBox p={3} lineHeight={1} style={{ minWidth: '500px', width:'50%' }}>
                        <div className="colContainer" >
                            <Tabs orientation={"horizontal"} value={dataSource} onChange={handleChangeSource} style={{ marginTop: '1px' }}>
                                {dqCorrelations.map((item, index) => {
                                    return <Tab key={index} label={item.label} value={item.value} style={{ padding: '15px' }} />
                                })}
                            </Tabs>
                            <Tabs orientation={"horizontal"} value={dataSubSource} onChange={handleChangeSubSource} style={{ marginTop: '1px' }}>
                                {getSubSourceValues(dataSource).map((item, index) => {
                                    return <Tab key={index} label={item.label} value={item.value} style={{ padding: '15px' }} />
                                })}
                            </Tabs>
                            <Tabs orientation={"horizontal"} value={dataQuality} onChange={handleChangeDataQuality} style={{ marginTop: '1px' }}>
                                {getDataQualities(dataSubSource).map((item, index) => {
                                    return <Tab key={index} label={item} value={item} style={{ padding: '15px' }} />
                                })}
                            </Tabs>
                        </div>
                    </MDBox >
                    <MDBox p={3} lineHeight={1}  style={{width:'50%' }}>
                        <div className="colContainer vline" >
                            {dataQuality !== "Overview" &&
                                <div className="colContainer" >
                                    <Box sx={{ minWidth: 160, marginTop: '5px' }}>
                                        <FormControl>
                                            <InputLabel id="select">Location</InputLabel>
                                            <Select
                                                labelId="select-location"
                                                id="select-location"
                                                value={location}
                                                label="Select Location type"
                                                onChange={(event) => (setlocation(event.target.value))}
                                                style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '7px', paddingRight: '7px', width: '140px' }}
                                            >
                                                <MenuItem value={"Networks"}>Networks</MenuItem>
                                                <MenuItem value={"Routes"}>Routes</MenuItem>
                                                <MenuItem value={"Intersections"}>Intersections</MenuItem>
                                                <MenuItem value={"Detectors"}>Detectors</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ minWidth: 160, marginTop: '20px' }}>
                                        <div className='rawContainer'>
                                            <FormControl >
                                                <InputLabel id="select">Timeframe</InputLabel>
                                                <Select
                                                    labelId="select-time"
                                                    id="select-time"
                                                    value={timeType}
                                                    label="Select timeframe type"
                                                    onChange={(event) => (setTimeType(event.target.value))}
                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '7px', paddingRight: '7px', width: '140px' }}
                                                >
                                                    <MenuItem value={"1h"}>Last 1 hour</MenuItem>
                                                    <MenuItem value={"12h"}>Last 12 hours</MenuItem>
                                                    <MenuItem value={"24h"}>Last 24 hours</MenuItem>
                                                    <MenuItem value={"customH"}>Custom hours</MenuItem>
                                                    <MenuItem value={"customD"}>Custom dates</MenuItem>
                                                </Select>
                                            </FormControl>
                                        {
                                            timeType === "customD" &&
                                            <Box sx={{ minWidth: 160 , marginLeft: '10px' }}>
                                                <div className='rawDPicker'>
                                                    <div className='fromtodp'>
                                                        <MDInput
                                                            type="datetime-local"
                                                            name="from"
                                                            value={customDates.from}
                                                            onChange={handleCustomDates}
                                                            label="Start date"
                                                        />
                                                    </div>
                                                    <div className='fromtodp'>
                                                        <MDInput
                                                            type="datetime-local"
                                                            name="to"
                                                            value={customDates.to}
                                                            onChange={handleCustomDates}
                                                            label="End date"
                                                        />
                                                    </div>
                                                </div>
                                            </Box>
                                        }
                                        {
                                            timeType === "customH" &&
                                            <Box sx={{ minWidth: 160, marginLeft: '10px' }}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Last hours"
                                                    type="number"
                                                    value={customHours}
                                                    onChange={(e) => (setCustomHours(parseInt(e.target.value)))}
                                                    style={{ width: '100px', height: '40px' }}
                                                />
                                            </Box>
                                        }
                                        </div>
                                    </Box>
                                    {
                                        applyEnabled()
                                            ? <MDButton variant="gradient" color="info" style={{ marginTop: '10px' }} onClick={apply}>Apply</MDButton>
                                            : <div className='rawContainerCenteredV'>
                                                <MDButton variant="gradient" color="info" style={{ marginTop: '10px' }} disabled>Apply</MDButton>  
                                                {
                                                    timeType==='customH'
                                                    ?<div className="errorBTNContainer"><span className='errortxtBTN'>The entered hours cannot be negative.</span><span className='errortxtBTN'> Please provide a valid value greater than zero.</span></div>
                                                    :<div className="errorBTNContainer"><span className="errortxtBTN">The end date cannot be earlier than the start date.</span><span className="errortxtBTN">Please adjust the dates to ensure the end date is on or after the start date.</span></div>
                                                }
                                              </div>
                                    }
                                </div>
                            }
                        </div>
                    </MDBox>
                </div>
            </Card>
            <Card style={{ marginTop: '3px' }}>
                <Grid container>
                    <Grid item xs={12} md={12} pt={3} px={3}>
                        <Overview />
                    </Grid>
                </Grid>
            </Card>
        </>


    )
}

export default Filters;

/*
                <div className='filterNtw'>
                    <MultiCascader data={output.spatial_filter_th_detectors}
                        block
                        columnWidth={220}
                        renderTreeNode={(label, node) => {
                            return (
                                <div>
                                    {label}
                                </div>
                            );
                        }}
                        renderColumn={(childNodes, { layer }) => {
                            return <Column header={headers[layer]}> {childNodes}</Column>;
                        }}
                        placeholder={
                            <span>
                                Select Locations
                            </span>
                        }
                        renderValue={(value, selectedItems, selectedElement) => (
                            <span>
                                <span style={{ color: '#575757' }}>
                                    Locations :
                                </span>{' '}
                                {selectedItems.map(item => item.label).join(' , ')}
                            </span>
                        )}
                    />
                    <MapSelective />
                </div>

*/