import { useState, useEffect } from "react";
import axios from "axios";

const API_TOKEN = process.env.REACT_APP_MAP_MATCHING_API_TOKEN;
const API_URL = process.env.REACT_APP_MAP_MATCHING_API_URL;

const useFieldsData = () => {
  const [fields, setFields] = useState({
    typeOfEvents: [],
    categories: [],
    subCategories: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(`${API_URL}/eventsFormLists`, {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        });
        setFields(response.data); // Save API data to state
        setLoading(false); // Data is loaded
      } catch (err) {
        setError("Error loading fields data.");
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData();
  }, []);

  return { fields, loading, error };
};

export default useFieldsData;
