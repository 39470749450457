
import React from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import dayjs from "dayjs";

function TimesListView({ times, selectedTimeIndex, onEdit, onDelete, onSelect, onAddTime }) {
  return (
    <Box p={3}>
      <Typography textAlign="center" variant="h4">Times</Typography>
      {times.map((time, index) => (
        <Paper
          key={index}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            cursor: "pointer",
            backgroundColor: index === selectedTimeIndex ? "lightblue" : "white",
            border: index === selectedTimeIndex ? "2px solid blue" : "1px solid lightgray",
          }}
          onClick={() => onSelect(index)}
        >
          <Box>
            <Typography variant="body1">
              {time.allDay
                ? "All day"
                : `${dayjs(time.from[0]).format("HH:mm")} - ${dayjs(time.to[0]).format("HH:mm")}`}
            </Typography>
          </Box>
          <Box>
            <IconButton
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(index);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Paper>
      ))}
      <MDButton variant="contained" color="info" size="medium"  sx={{ mt: 5, ml:9 }} onClick={onAddTime}>
        <Icon>add</Icon>&nbsp;Add new time range
      </MDButton>
    </Box>
  );
}

export default TimesListView;
