import { useState, useEffect } from "react";
import axios from "axios";

const API_TOKEN = process.env.REACT_APP_MAP_MATCHING_API_TOKEN;
const API_URL = process.env.REACT_APP_MAP_MATCHING_API_URL;

const useEventsData = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(`${API_URL}/events`, {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        });
        setEvents(response.data); // Save API data to state
        setLoading(false); // Data is loaded
      } catch (err) {
        setError("Error loading events data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { events, loading, error };
};

export default useEventsData;
