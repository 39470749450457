import useEventsData from "../../data/useEventsData";

const eventsTableData = () => {
  const { events, loading, error } = useEventsData();

  // Define the table headers
  const columns = [
    { Header: "#", accessor: "number" },
    { Header: "Type of event", accessor: "typeOfEvent" },
    { Header: "Category", accessor: "category" },
    { Header: "Valid From", accessor: "validFrom" },
    { Header: "Valid Until", accessor: "validUntil" },
    { Header: "Created", accessor: "created" },
    { Header: "Source", accessor: "source" },
    { Header: "Status", accessor: "status" },
  ];

  // Map the events to rows, flattening the nested data
  const rows = events.map((event, index) => ({
    id: event.id,
    number: index + 1, // Sequential number for each event
    typeOfEvent: event.type_of_event?.name || "Unknown",
    category: event.category?.name || "Unknown",
    subcategory: event.subcategory?.name || "Unknown",
    validFrom: new Date(event.valid_from).toLocaleString(), // Format date
    validUntil: new Date(event.valid_until).toLocaleString(), // Format date
    verification: event.verification || "N/A",
    source: event.source || "N/A",
    status: event.status?.name || "Unknown",
    geometry: event.geometry,
    links: event.link_id,
    fromNode: event.from_node,
    toNode: event.to_node,
    description: event.description,
    created: new Date(event.created_at).toLocaleString(),
  }));

  // Return the table-ready data
  return {
    tableData: { columns, rows },
    loading,
    error,
  };
};

export default eventsTableData;
