import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";

function TrajectoriesAnalytics() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <MDButton
        color="secondary"
        onClick={() => {
          navigate("/trajectories/dates");
        }}
      >
        Start
      </MDButton>
    </DashboardLayout>
  );
}

export default TrajectoriesAnalytics;
