import { useEffect, useState } from "react";
import Filters from "./components/Filters";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import filter from "./filter.json";

function DataQualityLayout() {

    //const [dropItems, setDropItems] = useState(null); // State for selected event
    // useEffect(() => {    
    //     setDropItems(filter.dataSourceMapping);
    // }, []);
    
    return (
        <DashboardLayout>
            <Card>
                <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        Data Quality Tool
                    </MDTypography>
                    <MDTypography variant="button" color="text">
                       Tool to analyze data quality and identify data issues.
                    </MDTypography>
                </MDBox>
            </Card>
            {Filters()}
        </DashboardLayout>
    );
}

export default DataQualityLayout;