import React from "react";
import { Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

function EmptyTimesView({ onAddTime }) {
  return (
    <Box textAlign="center" py={5}>
      <AccessTimeIcon sx={{ width: "80px", height: "80px", color: "grey.400" }} />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Nothing here yet
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        Add times to finish the step
      </Typography>
      <MDButton variant="contained" color="info" size="medium" sx={{ mt: 3 }} onClick={onAddTime}>
        <Icon>add</Icon>&nbsp;Add new time range
      </MDButton>
    </Box>
  );
}

export default EmptyTimesView;
