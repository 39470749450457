import useDataQualityData from "../../data/useDataQualityData";

const useDataQualityOverviewTable = () => {
  const { data, loading, error } = useDataQualityData();

  // Define the table headers
  const columns = [
    { Header: "Id", accessor: "detector_id" },
    { Header: "Date", accessor: "date" },
    { Header: "Intersection", accessor: "intersection" },
    { Header: "Vendor", accessor: "vendor" },
    { Header: "Flow", accessor: "flow" },
    { Header: "Link", accessor: "link" },
    { Header: "Detector", accessor: "detector" },
    { Header: "Expected records", accessor: "expected_records" },
    { Header: "Retrieved records", accessor: "retrieved_records" },
    { Header: "Completeness %", accessor: "percentage_completeness" },
    { Header: "Invalid count", accessor: "invalid_count" },
    { Header: "Invalid %", accessor: "percentage_invalid" },
    { Header: "Flow 0 count", accessor: "zero_flow_count" },
    { Header: "Flow 0 %", accessor: "percentage_zeroes" },
  ];

  const rows = data.map((item, index) => ({
    detector_id: item.detector_id || "Unknown",
    date: item.report_date,
    intersection: item.intersection || "Unknown",
    vendor: item.vendor || "Unknown",
    flow: item.flow || "Unknown",
    link: item.link || "Unknown",
    detector: item.detector || "Unknown",
    expected_records: item.expected_records || "Unknown",
    retrieved_records: item.retrieved_records || "Unknown",
    percentage_completeness: item.percentage_completeness || "Unknown",
    invalid_count: item.invalid_count || "Unknown",
    percentage_invalid: item.percentage_invalid || "Unknown",
    zero_flow_count: item.zero_flow_count || "Unknown",
    percentage_zeroes: item.percentage_zeroes || "Unknown",
  }));

  // Return the table-ready data
  return {
    tableData: { columns, rows },
    loading,
    error,
  };
};

export default useDataQualityOverviewTable;
