import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import useFieldsData from "./EventForm/data/useFieldsData";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const API_TOKEN = process.env.REACT_APP_MAP_MATCHING_API_TOKEN;
const API_URL = process.env.REACT_APP_MAP_MATCHING_API_URL;

// Utility function to get name by ID
const getNameById = (data, id) => {
  if (!data) return ""; // Return default value if data is undefined
  const item = data.find((entry) => entry.id === id);
  return item ? item.name : ""; // Return the name if found, otherwise "N/A"
};

function MapPreviewEvent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData } = location.state || {};
  const mapContainerRef = useRef(null); // Reference for the map container
  const mapRef = useRef(null); // Reference to store the Mapbox map instance
  const { fields } = useFieldsData(); // Fetch fields data

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure `link_id` is an array
    const linkIdArray = Array.isArray(formData.link_id) ? formData.link_id : [formData.link_id]; // Convert to array if it's not already
    // Normalize geometry coordinates
    const normalizedGeometry = { ...formData.geometry };

    if (formData.geometry.type === "Point") {
      // Wrap `Point` coordinates in an array to make it consistent
      normalizedGeometry.coordinates = [formData.geometry.coordinates];
      normalizedGeometry.type = "Point";
    }

    const { locations, ...filteredFormData } = formData;

    const submitFormData = {
      ...filteredFormData,
      link_id: linkIdArray,
      geometry: normalizedGeometry,
      source: 1,
      status: 1,
      verification: 1,
    };

    try {
      await axios.post(`${API_URL}/events`, submitFormData, {
        headers: {
          "X-API-Key": API_TOKEN,
        },
      });

      // Redirect to /events/show
      navigate("/events/show");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      }
    }
  };

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current, // The HTML element ID for the map
      style: "mapbox://styles/mapbox/dark-v11",
      center: [22.9527, 40.6253], // Map center coordinates
      zoom: 12, // Initial zoom level
    });

    // Optional: Add a handler for when the map loads
    mapRef.current.on("load", () => {
      // console.log("Preview Map Loaded, Received Data:", formData);
      // Wrap the geometry in a valid GeoJSON feature
      const locationFeature = {
        type: "Feature",
        geometry: formData.geometry,
      };

      const geojsonData = {
        type: "FeatureCollection",
        features: [locationFeature],
      };

      // console.log("data to sourec MapPreview", geojsonData);

      mapRef.current.addSource("previewData", {
        type: "geojson",
        data: geojsonData,
      });

      // console.log("messageToDisplay", formData.locations.PathID.length);

      // create the message
      const messageToDisplay = `
      <div style="font-family: Arial, sans-serif;">
<h3 style="margin: 0;">${getNameById(fields.type, formData.type_of_event)}</h3>
        <h3 style="margin: 0;">${getNameById(fields.category, formData.category)}</h3>
        <h3 style="margin: 0;">${getNameById(fields.subcategory, formData.subcategory)}</h3>
        <h4 style="margin: 0;">${formData.valid_from}</h4>
        <h4 style="margin: 0;">${formData.valid_until}</h4>
        <h4 style="margin: 0;">${formData.link_id.length}</h4>
        <h4 style="margin: 0;">${formData.to_node}</h4>
        <p style="margin: 0;">${formData.description}</p>
      </div>
    `;

      // Add the appropriate layer
      const locationType = formData.geometry.type;
      if (locationType === "Point") {
        // console.log("location type is point", geojsonData);
        mapRef.current.addLayer({
          id: "preview-event",
          type: "circle",
          source: "previewData",
          paint: {
            "circle-radius": 10,
            "circle-color": "#007cbf",
          },
        });
        const firstPoint = formData.geometry.coordinates;
        new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(firstPoint)
          .setHTML(messageToDisplay)
          .addTo(mapRef.current);
      } else if (locationType === "LineString") {
        mapRef.current.addLayer({
          id: "preview-event",
          type: "line",
          source: "previewData",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#007cbf",
            "line-width": 2,
          },
        });
        const firstPoint = formData.geometry.coordinates[0];
        new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat(firstPoint)
          .setHTML(messageToDisplay)
          .addTo(mapRef.current);
      }

      mapRef.current.on("mouseenter", "preview-event", () => {
        mapRef.current.getCanvas().style.cursor = "pointer";
      });

      mapRef.current.on("mouseleave", "preview-event", () => {
        mapRef.current.getCanvas().style.cursor = "";
      });
      // Add click event for popup
      mapRef.current.on("click", "preview-event", (e) => {
        // console.log("e", e);
        const coordinates = e.lngLat;
        // console.log(coordinates);
        new mapboxgl.Popup().setLngLat(coordinates).setHTML(messageToDisplay).addTo(mapRef.current);
      });
    });

    // Cleanup when component unmounts
    return () => {
      if (mapRef.current.getLayer("preview-event")) {
        mapRef.current.removeLayer("preview-event");
      }
      if (mapRef.current.getSource("previewData")) {
        mapRef.current.removeSource("previewData");
      }
      mapRef.current.remove();
    };
  }, []); // Re-run if formData changes (if necessary)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <h1>This is the Map Preview Page</h1>
      </MDBox>
      <MDButton color="secondary" onClick={handleSubmit}>
        Submit
      </MDButton>
      <MDBox
        id="map-preview-event"
        ref={mapContainerRef}
        sx={{ width: "100%", height: "70vh", mt: 3 }}
      />
    </DashboardLayout>
  );
}

export default MapPreviewEvent;
