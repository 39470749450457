/**
=========================================================
* Otis Admin PRO - v2.0.2
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import SignInBasic from "layouts/authentication/sign-in/basic";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import CreateEvent from "layouts/events/create";
import Events from "layouts/events/show";
import MapCreateEvent from "layouts/events/create/components/MapCreateEvent";
import TrajectoriesAnalytics from "layouts/trajectories";
import Dates from "layouts/trajectories/steps/Dates";
import Times from "layouts/trajectories/steps/Times";
import Link from "layouts/trajectories/steps/Link";
import Summary from "layouts/trajectories/steps/Summary";
import MapPreviewEvent from "layouts/events/create/components/MapPreviewEvent";
import Results from "layouts/trajectories/results/Results";
import DataQualityLayout from "layouts/dataquality";

const routes = [
  // nav link
  // {
  //   type: "collapse",
  //   name: "Test",
  //   key: "test",
  //   icon: <Icon fontSize="medium">Test</Icon>,
  //   collapse: [
  //     {
  //       name: "Mapbox",
  //       key: "Mapbox",
  //       route: "/events/mapbox",
  //       component: <Mapbox />,
  //     },
  //     {
  //       name: "Deck",
  //       key: "Deck",
  //       route: "/events/deck",
  //       component: <Deck />,
  //     },
  //   ],
  // },
  {
    type: "single",
    name: "Traffic Monitoring",
    key: "trafficMonitoring",
    icon: <Icon fontSize="medium">monitoring</Icon>,
    route: "/dashboards/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "single",
    name: "Data Quality",
    key: "dataQuality",
    icon: <Icon fontSize="medium">storage</Icon>,
    route: "/dashboards/dataquality",
    component: <DataQualityLayout />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "MTM Scenarios",
    key: "mtmScenarios",
    icon: <Icon fontSize="medium">space_dashboard</Icon>,
    collapse: [
      {
        name: "Create Scenario",
        key: "createScenario",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Scenarios Library",
        key: "scenariosLibrary",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Scenarios Statistics",
        key: "scenariosStatistics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "MTM Scenarios Log",
        key: "mtmScenariosLog",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "TMPX Log",
        key: "tmpxLog",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Event Management",
    key: "events",
    icon: <Icon fontSize="medium">public</Icon>,
    collapse: [
      {
        name: "Create Event",
        key: "createEvents",
        route: "/events/create",
        component: <CreateEvent />,
      },
      {
        name: "Events Library",
        key: "showEvents",
        route: "/events/show",
        component: <Events />,
      },
    ],
  },
  {
    type: "single",
    name: "Trajectories Analytics",
    key: "trajectoriesAnalytics",
    icon: <Icon fontSize="medium">apps</Icon>,
    route: "/trajectories",
    component: <TrajectoriesAnalytics />,
    noCollapse: true,
  },
  {
    type: "single",
    name: "Data Repo",
    key: "dataRepo",
    icon: <Icon fontSize="medium">insert_chart</Icon>,
    route: "/dashboards/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "single",
    name: "Tools",
    key: "tools",
    icon: <Icon fontSize="medium">build</Icon>,
    route: "/dashboards/analytics",
    component: <Analytics />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-0" },
  // settings
  {
    type: "collapse",
    name: "Settings",
    key: "brooklyn-alice",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  // just links
  {
    route: "/events/create/map-create-event",
    key: "mapCreateEvent",
    component: <MapCreateEvent />,
  },
  {
    route: "/events/map-preview-event",
    key: "mapPreviewEvent",
    component: <MapPreviewEvent />,
  },
  {
    route: "/trajectories/dates",
    key: "dates",
    component: <Dates />,
  },
  {
    route: "/trajectories/times",
    key: "times",
    component: <Times />,
  },
  {
    route: "/trajectories/link",
    key: "link",
    component: <Link />,
    roles: ["super", "editor", "thessaloniki", "madrid"],
  },
  {
    route: "/trajectories/summary",
    key: "summary",
    component: <Summary />,
  },
  {
    route: "/trajectories/result",
    key: "result",
    component: <Results />,
  },
];

export default routes;
