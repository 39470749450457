
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SelectionService from "../../../services/SelectionService";
import EmptyTimesView from "../times/EmptyTimesView";
import AddTimeView from "../times/AddTimeView";
import TimesListView from "../times/TimesListView";
import StepperNav from "../StepperNav";


function Times() {
  const [view, setView] = useState("empty"); // empty, add, list
  const [times, setTimes] = useState([]);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedTimes = SelectionService.getTimes();
    const savedIndex = SelectionService.getSelectedTime();

    if (savedTimes.length > 0) {
      setTimes(savedTimes);
      setView("list");
    }

    if (savedIndex !== null) setSelectedTimeIndex(savedIndex);
  }, []);

  const handleAddTime = () => setView("add");

  const handleSaveTime = (newTime) => {
    if (newTime === null) {
      console.log(newTime);
      if (times.length > 0) {
        setView("list");
      } else {
        setView("empty");
      }
    } else {
    const updatedTimes = [...times, newTime];
    setTimes(updatedTimes);
    SelectionService.setTimes(updatedTimes);
    setView("list");
    }
  };

  const handleEditTime = (index) => {
    // Edit logic can be added here
  };

  const handleDeleteTime = (index) => {
    SelectionService.deleteTime(index);
    const updatedTimes = SelectionService.getTimes();
    setTimes(updatedTimes);

    if (updatedTimes.length === 0) {
      setView("empty");
    }
  };

  const handleSelectTime = (index) => {
    setSelectedTimeIndex(index);
    SelectionService.setSelectedTime(index);
  };

  const handleProceedToNextStep = () => {
    navigate("/trajectories/link"); // Proceed to the next step
  };

  const handleProceedToPreviousStep = () => {
    navigate("/trajectories/dates"); // Go back to the previous step
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container direction="row" sx={{ alignItems: "stretch", height: "85vh" }}>
        <Box sx={{ width: "30%", height: "85vh", display: "flex", flexDirection: "column" }}>
          <Grid container direction="column" sx={{ flex: 1 }} >
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
            {view === "empty" && <EmptyTimesView onAddTime={handleAddTime} />}
            {view === "add" && <AddTimeView onSave={handleSaveTime} />}
            {view === "list" && (
              <TimesListView
                times={times}
                selectedTimeIndex={selectedTimeIndex}
                onEdit={handleEditTime}
                onDelete={handleDeleteTime}
                onSelect={handleSelectTime}
                onAddTime={handleAddTime}
              />
            )}
            </Grid>
            <Grid item 
            xs="auto"
            sx={{
            mt: "auto", // Pushes the grid to the bottom of the container
            textAlign: "right", // Aligns buttons to the right
            }}>
         <Grid container justifyContent="flex-end" spacing={2}>
         <Grid item>
              <MDButton 
                variant="outlined"
                color="info"
                size="medium"  onClick={handleProceedToPreviousStep}>
                Back
              </MDButton>
              </Grid>
              <Grid item>
              <MDButton
                variant="contained"
                color="info"
                size="medium"
                disabled={selectedTimeIndex === null}
                onClick={handleProceedToNextStep}>
                Next
              </MDButton>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flex: 1 }}>
          <StepperNav />
        </Box>
      </Grid>
    </DashboardLayout>
  );
}

export default Times;
