import React from "react";
import PropTypes from "prop-types";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import eventsTableData from "./eventsTableData";

function EventsTable({ onRowClick }) {
  const { tableData, loading, error } = eventsTableData();

  if (loading) {
    return (
      <MDBox p={3}>
        <MDTypography variant="h6" fontWeight="medium" gutterBottom>
          Loading Events...
        </MDTypography>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox p={3}>
        <MDTypography variant="h6" color="error" gutterBottom>
          Error: {error}
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <MDBox p={3}>
      <MDTypography variant="h6" fontWeight="medium" gutterBottom>
        Events List
      </MDTypography>
      <DataTable
        table={tableData}
        canSearch
        onRowClick={onRowClick} // Pass click handler from parent
      />
    </MDBox>
  );
}

EventsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired, // Validate that onRowClick is provided
};

export default EventsTable;
