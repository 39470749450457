import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import EventForm from "./components/EventForm";
// import MapCreateEvent from "./compoenents/MapCreateEvent";

function CreateEvent() {
  return (
    <DashboardLayout>
      <MDBox mt={4} p={0}>
        <EventForm />
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateEvent;
