import React, {useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import OverviewHandler from "../tables/OverviewHandler";

const Overview = () => {

    return (
        <div className='rawContainerCenteredH'>
            <OverviewHandler />
        </div>
    );
};

export default Overview;