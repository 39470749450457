import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Timeline context
import { useTimeline } from "examples/Timeline/context";
// Otis Admin PRO React context
import { useMaterialUIController } from "context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";

function EventList({ events, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const isDark = useTimeline();

  // State for the "More" menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      {events.map((event, index) => (
        <MDBox
          key={event.id}
          position="relative"
          mb={3}
          sx={(theme) => timelineItem(theme, { lastItem: index === events.length - 1, isDark })}
        >
          {/* Timeline icon */}
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor={event.color || "info"}
            color="white"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            position="absolute"
            top="8%"
            left="2px"
            zIndex={2}
            sx={{ fontSize: ({ typography: { size } }) => size.sm }}
          >
            <Icon fontSize="inherit">{event.icon || "event"}</Icon>
          </MDBox>

          {/* Event content */}
          <MDBox ml={5.75} pt={event.description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
            <MDBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              bgColor={darkMode ? "transparent" : "grey-100"}
              borderRadius="lg"
              p={1}
              mb={noGutter ? 0 : 1}
            >
              <MDBox width="100%" display="flex" flexDirection="column">
                {/* Title and Actions */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color={isDark ? "white" : "dark"}
                    component="a"
                    href="#"
                    onClick={() => console.log(`Title clicked for event: ${event.id}`)}
                    sx={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    {event.title}
                  </MDTypography>

                  <MDBox>
                    <MDButton
                      variant="text"
                      color={darkMode ? "white" : "dark"}
                      onClick={handleOpenMenu}
                    >
                      <Icon>more_vert</Icon>
                    </MDButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      keepMounted
                    >
                      <MenuItem onClick={() => console.log("View clicked")}>
                        <Icon fontSize="small">visibility</Icon>&nbsp;View
                      </MenuItem>
                      <MenuItem onClick={() => console.log("Edit clicked")}>
                        <Icon fontSize="small">edit</Icon>&nbsp;Edit
                      </MenuItem>
                      <MenuItem onClick={() => console.log("Delete clicked")}>
                        <Icon fontSize="small">delete</Icon>&nbsp;Delete
                      </MenuItem>
                    </Menu>
                  </MDBox>
                </MDBox>

                {/* Event Details */}
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" color="text">
                    Type:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {event.type}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" color="text">
                    Source:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {event.source}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" color="text">
                    Date:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {event.date}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" color="text">
                    Status:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium">
                      {event.status}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDTypography variant="caption" color="text">
                  Verification:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium">
                    {event.verification}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ))}
    </>
  );
}

EventList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      date: PropTypes.string.isRequired,
      type: PropTypes.string,
      source: PropTypes.string,
      status: PropTypes.string,
      verification: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  noGutter: PropTypes.bool,
};

EventList.defaultProps = {
  events: [],
  noGutter: false,
};

export default EventList;
