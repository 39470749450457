// src/layouts/events/create/components/EventForm.js
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import useFieldsData from "./data/useFieldsData";

function EventForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { fields, loading, error } = useFieldsData(); // Fetch fields data

  // Default form data if location.state or formData is not provided
  const initialFormData = {
    type_of_event: "",
    category: "",
    subcategory: "",
    valid_from: "",
    valid_until: "",
    geometry: {
      type: "", // e.g., "Point" or "LineString"
      coordinates: [], // Array of coordinates
    },
    link_id: [], // Array of IDs corresponding to the geometry
    from_node: "",
    to_node: "",
    description: "",
  };
  // Use the provided formData or fallback to the initial default
  const [formData, setFormData] = useState(location.state?.formData || initialFormData);
  const [errors, setErrors] = useState({});
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  // Update formData if new data is passed via location.state
  useEffect(() => {
    if (location.state?.formData) {
      console.log("Received formData:", location.state.formData.locations);

      const { locations } = location.state.formData; // Extract locations
      if (locations) {
        setFormData((prev) => ({
          ...prev,
          geometry: locations.geometry, // Extract geometry from locations
          link_id: locations.PathID, // Extract PathID as link_id
        }));
      }
    }
  }, [location.state]);

  const handleInputChange = (eventOrFieldName, newValue) => {
    if (eventOrFieldName.target) {
      // Handle standard input fields
      const { name, value } = eventOrFieldName.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Handle Autocomplete fields
      const fieldName = eventOrFieldName; // Explicitly set the field name
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: newValue?.id || "", // Store the id of the selected option
      }));

      // Handle subcategory filtering when category changes
      if (fieldName === "category") {
        const filteredSubs = fields.subcategory.filter((sub) => sub.category_id === newValue?.id);
        setFilteredSubcategories(filteredSubs);
        setFormData((prev) => ({ ...prev, subcategory: "" })); // Reset subcategory
      }
    }
  };

  const handleOpenMap = () => {
    // console.log("Navigating to MapCreateEvent with formData:", formData);
    navigate("/events/create/map-create-event", { state: { formData } });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.type_of_event) newErrors.type_of_event = "Type of Event is required.";
    if (!formData.category) newErrors.category = "Category is required.";
    if (!formData.valid_from) newErrors.valid_from = "Valid From is required.";
    if (!formData.geometry.coordinates.length) {
      newErrors.geometry = "Event location is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    // console.log("Submitted Form Data:", formData);
    navigate("/events/map-preview-event", { state: { formData } }); // Pass form data to the preview page
    // Send formData to backend API or process it
  };

  if (loading) return <MDTypography variant="h6">Loading fields...</MDTypography>;
  if (error)
    return (
      <MDTypography variant="h6" color="error">
        {error}
      </MDTypography>
    );

  return (
    <Card id="event-form" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Entry of events & incidents</MDTypography>
      </MDBox>
      <MDBox compoent="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDTypography
              compoent="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Type
            </MDTypography>
            <Autocomplete
              value={fields.typeOfEvents.find((type) => type.id === formData.type_of_event) || null}
              options={fields.typeOfEvents}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, selectedOption) =>
                setFormData({ ...formData, type_of_event: selectedOption?.id || "" })
              }
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  error={!!errors.type_of_event}
                  helperText={errors.type_of_event}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography
              compoent="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Category
            </MDTypography>
            <Autocomplete
              value={fields.categories.find((cat) => cat.id === formData.category) || null}
              options={fields.categories}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, selectedOption) => {
                setFormData({ ...formData, category: selectedOption?.id || "", subcategory: "" });
                const filteredSubs =
                  fields.subCategories.filter((sub) => sub.category_id === selectedOption?.id) ||
                  [];
                setFilteredSubcategories(filteredSubs);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  error={!!errors.category}
                  helperText={errors.category}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDTypography
              compoent="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Subcategory
            </MDTypography>
            <Autocomplete
              value={filteredSubcategories.find((sub) => sub.id === formData.subcategory) || null}
              options={filteredSubcategories}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, selectedOption) =>
                setFormData({ ...formData, subcategory: selectedOption?.id || -1 })
              }
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column">
            <MDTypography compoent="label" variant="button" fontWeight="regular" color="text">
              Start date-time
            </MDTypography>
            <MDInput
              type="datetime-local"
              name="valid_from"
              value={formData.valid_from}
              onChange={handleInputChange}
              error={!!errors.valid_from}
              helperText={errors.valid_from}
            />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column">
            <MDTypography compoent="label" variant="button" fontWeight="regular" color="text">
              End date-time
            </MDTypography>
            <MDInput
              type="datetime-local"
              name="valid_until"
              value={formData.valid_until}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDButton variant="gradient" color="secondary" onClick={handleOpenMap}>
              Select Event location
            </MDButton>
            {errors.geometry && (
              <MDTypography variant="caption" color="error">
                {errors.geometry}
              </MDTypography>
            )}
            {formData.geometry.coordinates.length > 0 && (
              <MDBox mt={2}>
                <MDTypography variant="button" fontWeight="light" color="success">
                  Geometry successfully selected!
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="from_node"
              label="From node"
              value={formData.from_node}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="to_node"
              label="To node"
              value={formData.to_node}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDEditor
              value={formData.description}
              onChange={(value) => handleInputChange({ target: { name: "description", value } })}
            />
          </Grid>
          <Grid mt={3} item xs={12} sm={12}>
            <MDButton variant="gradient" color="secondary" onClick={handleSubmit}>
              Preview
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default EventForm;
