// @mui material components
import { useState } from "react";
// Otis Admin PRO React components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Card } from "@mui/material";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EventList from "customComponents/EventList";

import EventsMap from "./components/eventsMap";

import EventsTable from "./components/eventsTable";

function Events() {
  // Example data for events
  const events = [
    {
      title: "Vehicle Collision",
      description: "A planned vehicle collision test.",
      date: "23/12/2024",
      type: "Planned",
      source: "TMO",
      status: "Active",
      verification: "Yes (Manually No)",
      icon: "car_crash", // Material UI icon name
      color: "error", // Theme color (e.g., primary, secondary, success, warning, error)
    },
    {
      title: "System Maintenance",
      description: "Scheduled downtime for system upgrade.",
      date: "25/12/2024",
      type: "Maintenance",
      source: "Admin",
      status: "In Progress",
      verification: "Yes",
      icon: "build", // Material UI icon name
      color: "warning",
    },
    {
      title: "Project Deadline",
      description: "Submission of the final project report.",
      date: "30/12/2024",
      type: "Deadline",
      source: "Project Team",
      status: "Upcoming",
      verification: "No",
      icon: "event", // Material UI icon name
      color: "info",
    },
  ];
  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event

  // Handle row click
  const handleRowClick = (row) => {
    console.log("handleRowClick", row);
    setSelectedEvent(row);
  };

  return (
    <DashboardLayout>
      <Card sx={{ mb: 3 }}>
        <MDBox p={3} lineHeight={1}>
          <Icon fontSize="inherit">filter_alt</Icon>
          <MDTypography variant="h5" fontWeight="medium">
            Mapbox Events
          </MDTypography>
          <MDTypography variant="button" color="text">
            A lightweight, extendable, dependency-free javascript HTML table plugin.
          </MDTypography>
        </MDBox>
      </Card>

      <Card>
        <Grid container>
          <Grid item xs={12} md={4} pt={3} px={3}>
            <EventList events={events} noGutter={false} />
          </Grid>
          <Grid item xs={12} md={8}>
            <EventsMap selectedEvent={selectedEvent} />
          </Grid>
        </Grid>
      </Card>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          Mapbox Events
        </MDTypography>
        <MDTypography variant="button" color="text">
          A lightweight, extendable, dependency-free javascript HTML table plugin.
        </MDTypography>
      </MDBox>
      <EventsTable onRowClick={handleRowClick} />
    </DashboardLayout>
  );
}

export default Events;
